import React from 'react';
import { BodyPortalSlotsContext } from '@openstax/ui-components';
import { ToastProvider } from "../../toasts/ToastContext";
import type {ActivityState} from '@openstax/ts-utils/services/lrsGateway/attempt-utils';
import * as UI from '@openstax/ui-components';
import type {MappedUserInfo} from '@openstax/ts-utils/services/accountsGateway';
import { createRoute, makeScreen } from "../../core/services";
import { stateHasData } from "@openstax/ts-utils/fetch";
import { Auth } from "../components/Auth";
import { AssignmentSubmissionList } from "../components/AssignmentSubmissionList";
import { AssignmentSubmissionDetails } from "../components/AssignmentSubmissionDetails";
import { useLaunchTokenData } from "../../auth/useTokenData";
import { useAssignmentManagementStates, AssignmentManagementStates } from "../hooks/grades";
import type {
  AssignmentReadResponse
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";
import useHelpMenu from '../../components/HelpMenu';
import { useGetAssignmentByResource } from '../hooks/resourceLink';
import { assertDefined } from '@openstax/ts-utils/assertions';

export const ReviewSubmissionInterface = ({assignment, assignmentManagementStates, onEditAssignment}: {
  assignment: AssignmentReadResponse;
  assignmentManagementStates: AssignmentManagementStates;
  onEditAssignment?: () => void;
}) => {
  const launchTokenData = useLaunchTokenData();
  const [selectedAttempt, selectAttempt] = React.useState<MappedUserInfo<ActivityState> | undefined>();
  const [HelpMenu, ContactFormIframe] = useHelpMenu();

  return <>
    {launchTokenData.branding !== false ?
      <>
        <UI.NavBar logo={true}><HelpMenu id={assignment.id} /></UI.NavBar>
        <ContactFormIframe />
      </>
      :
      null}
    {selectedAttempt
      ? <AssignmentSubmissionDetails
        onBack={() => selectAttempt(undefined)}
        assignment={assignment}
        attempt={selectedAttempt}
      />
      : <AssignmentSubmissionList
        onEditAssignment={onEditAssignment}
        onSelectAttempt={selectAttempt}
        assignmentManagementStates={assignmentManagementStates}
      />
    }
  </>;
};

const ReviewSubmissionScreenComponent = ({assignmentId}: {assignmentId?: string} & JSX.IntrinsicAttributes) => {
  const assignment = useGetAssignmentByResource(assertDefined(assignmentId, 'Missing assignment ID'));
  const assignmentManagementStates = useAssignmentManagementStates(stateHasData(assignment)
    ? assignment.data?.id
    : undefined
  );

  return <ToastProvider>
    <BodyPortalSlotsContext.Provider value={['nav', 'root']}>
      <Auth>
        {stateHasData(assignment)
          ? <ReviewSubmissionInterface
              assignment={assignment.data}
              assignmentManagementStates={assignmentManagementStates}
          />
          : null
        }
      </Auth>
    </BodyPortalSlotsContext.Provider>
  </ToastProvider>;
};

export const reviewSubmissionScreen = createRoute({name: 'reviewSubmissionScreen',
  path: '/instructor/review/:assignmentId',
  handler: makeScreen(ReviewSubmissionScreenComponent)
});
