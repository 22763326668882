import React from 'react';
import { StatefulAssignmentResponse } from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/student";
import { createRoute, makeScreen } from "../../core/services";
import { useApiClient } from "../../api";
import {
  FetchState,
  fetchLoading,
  fetchSuccess,
  stateHasData,
  stateHasError
} from "@openstax/ts-utils/fetch";
import { Assignment } from "../components/Assignment";
import * as UI from '@openstax/ui-components';
import { useServices } from "../../core/context/services";
import { Auth } from "../../instructor/components/Auth";
import { ToastProvider } from "../../toasts/ToastContext";
import { assertDefined } from '@openstax/ts-utils/assertions';

const useLaunchAssignment = (id: string) => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const {launchToken} = useServices();
  const [state, setState] = React.useState<FetchState<StatefulAssignmentResponse, string>>(fetchLoading());

  React.useEffect(() => {
    apiClient.apiV0ReadAssignment({params: {id}, payload: {launchToken}})
      .then(response => response.acceptStatus(200, 201))
      .then(response => response.load())
      .then(response => {
        if (response) {
          setState(fetchSuccess(response));
        }
      })
      .catch(setAppError)
    ;
  }, [apiClient, id, setAppError, launchToken]);

  return state;
};

const Launch = ({id}: {id?: string} & JSX.IntrinsicAttributes) => {
  const assignmentState = useLaunchAssignment(assertDefined(id, 'Missing assignment ID'));

  return <ToastProvider>
    <Auth>
      {authState => stateHasError(assignmentState)
        ? <UI.Error />
        : stateHasData(assignmentState) && stateHasData(authState)
          ? <Assignment assignment={assignmentState.data} user={authState.data} />
          : <UI.Loader />
      }
    </Auth>
  </ToastProvider>;
};

export const launchScreen = createRoute({name: 'LaunchScreen', path: '/launch/:id',
  handler: makeScreen(Launch)
});
