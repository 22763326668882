import * as UI from '@openstax/ui-components';
import React from 'react';
import { fetchSuccess } from '@openstax/ts-utils/fetch';
import { Option } from './Option';
import { Bold, Row, SettingsGroup } from './styles';
import { AssignmentOptions, useAssignmentOptions } from '../../hooks/options';

const Forms = UI.Forms.Controlled;

type OptionProps = {
  assignmentOptions: ReturnType<typeof useAssignmentOptions>;
  editingDisabled?: boolean;
};

export const QuestionSettings = (props: OptionProps) => {
  const {assignmentOptions, handleOptionChange, optionGroups, optionIsDisabled} = props.assignmentOptions;
  const optionFormState = fetchSuccess<AssignmentOptions>(assignmentOptions.assignmentOptions);

  if (!Object.keys(optionGroups).length || !Object.keys(assignmentOptions).length) {
    return null;
  }

  return <>{Object.entries(optionGroups).map(([integration, config]) =>
    Object.entries({[integration]: config.optionGroups, ...config.activities}).map(([type, groups], i) => {

      const activityType = type === integration ? undefined : type;
      const optionGroupState = assignmentOptions[integration]
        ? (activityType
          ? assignmentOptions[integration].activities?.[type]
          : assignmentOptions[integration].options)
        : {};

      return groups.map((group) => <React.Fragment key={i}>
        <UI.H3>{group.title}</UI.H3>
        <SettingsGroup>
          <Forms.Form state={optionFormState}>
          {group.options.map((option) =>
            <Option
              key={option.name}
              option={option as object}
              optionGroupState={optionGroupState}
              handleChange={(e: React.UIEvent) => {
                if (!props.editingDisabled) {
                  const target = e.target as HTMLInputElement;

                  handleOptionChange(target.value, {integration, activityType, option: target.name});
                }
              }}
              handleDisabledCheck={(disabler: {optionName: string; optionValue: string | boolean}) =>
                optionIsDisabled(
                  integration,
                  {name: disabler.optionName, value: disabler.optionValue as string},
                  activityType
                )
              }
            />
          )}
          </Forms.Form>
        </SettingsGroup>
      </React.Fragment>
    );}
    ))}
  </>;
};

export const AssignmentSettings = (props: {points: number}) => <>
  <UI.H3>Assignment Settings</UI.H3>
  <SettingsGroup>
    <Row>
      <Bold>Assignment total</Bold>
      <Bold>{props.points} points</Bold>
    </Row>
  </SettingsGroup>
</>;
