import React from 'react';
import { callbackContinue } from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/interface.config";
import { createRoute, makeScreen } from "../../core/services";
import { usePostComplete } from "../hooks/usePostMessage";
import {Loader} from '@openstax/ui-components';

export const Callback = () => {
  usePostComplete();

  return <Loader />;
};

export const callbackScreen = createRoute({name: 'CallbackScreen', path: callbackContinue,
  handler: makeScreen(Callback)
});
