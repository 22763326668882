import React from 'react';
import { FetchState, fetchIdle, fetchLoading, fetchSuccess } from '@openstax/ts-utils/fetch';
import { useApiClient } from '../../api';
import * as UI from '@openstax/ui-components';
import {
  AssignmentReadResponse
} from '@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments';
import { useServices } from '../../core/context/services';

export const useGetAssignmentByResource = (id: string) => {
  const {launchToken} = useServices();

  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<AssignmentReadResponse, string>>(
    id ? fetchLoading() : fetchIdle()
  );

  React.useEffect(() => {
    if (!id) {
      return;
    }
    apiClient.apiV0GetAssignmentByResource({
      params: {id},
      query: {t: launchToken}
    })
      .then(response => response.acceptStatus(200))
      .then(response => response.load())
      .then(response => setState(fetchSuccess(response)))
      .catch(setAppError)
    ;
  }, [apiClient, id, setAppError, launchToken]);

  return state;
};
