import React from "react";
import { stateHasData } from "@openstax/ts-utils/fetch";
import { warnings } from "../../constants/messages";
import { AssignmentManagementStates } from "../../hooks/grades";

export default function useWarningMessages({
  editView,
  assignmentManagementStates,
  isForkedCopy,
  assignmentData,
  isLegacyAssignmentInCopiedCourse,
}: {
  editView?: boolean;
  assignmentManagementStates?: AssignmentManagementStates;
  isForkedCopy?: boolean;
  assignmentData?: object;
  isLegacyAssignmentInCopiedCourse: boolean;
}) {
  return React.useMemo(() => {
    if (!editView) {
      return [warnings.create, warnings.copy];
    }

    const hasAttempts =
      assignmentManagementStates &&
      stateHasData(assignmentManagementStates.assignmentAttemptsState) &&
      assignmentManagementStates.assignmentAttemptsState.data.length > 0;

    const warningMessages: string[] = [];

    if (isForkedCopy) {
      warningMessages.push(warnings.editForkedCopy);
    }

    if (isLegacyAssignmentInCopiedCourse) {
      warningMessages.push(warnings.editLegacyCopy);
    } else {
      const isLegacyAssignment =
        assignmentData !== undefined &&
        !("resourceRegistration" in assignmentData);
      warningMessages.push(
        isLegacyAssignment ? warnings.editOriginalLegacy : warnings.editOriginal
      );
    }

    if (hasAttempts) {
      warningMessages.push(warnings.existingAttempts);
    }

    return warningMessages;
  }, [
    editView,
    assignmentManagementStates,
    isForkedCopy,
    isLegacyAssignmentInCopiedCourse,
    assignmentData,
  ]);
}
