import React from "react";
import * as styles from "./styles";
import ButtonGroup from "../ButtonGroup";
import { SubScopeItem } from "../../types";
import { renderRouteUrl } from "../../../core";
import { launchScreen } from "../../../student/screens/Launch";
import { useServices } from "../../../core/context/services";
import {
  FlattenedActivityStep,
  stepIsRemoved,
} from "../../utils/activity-step-utils";
import {
    CreateAssignmentStepPayload
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";
import { AssignmentOptions } from "../../hooks/options";

export default function ActionBar({
  onShowSubmissions,
  subScope,
  showPreview,
  isLegacyAssignmentInCopiedCourse,
  id,
  setShowPreview,
  setFirstSelectedResource,
  editView,
  onSave,
  loading,
  flatActivities,
  setFlatActivities,
  assignmentOptions,
  hasCustomOrder,
}: {
  onShowSubmissions?: () => void;
  subScope: "support" | SubScopeItem;
  showPreview: boolean;
  isLegacyAssignmentInCopiedCourse: boolean;
  id?: string;
  setShowPreview: React.Dispatch<boolean>;
  setFirstSelectedResource: React.Dispatch<React.SetStateAction<boolean>>;
  editView?: boolean;
  onSave: (
    flatActivities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions
  ) => void;
  loading?: boolean;
  flatActivities: FlattenedActivityStep[];
  setFlatActivities: React.Dispatch<
    React.SetStateAction<FlattenedActivityStep[]>
  >;
  assignmentOptions: AssignmentOptions;
  hasCustomOrder: boolean;
}) {
  const prepareFlatActivitiesForSave = (
    steps: FlattenedActivityStep[]
  ): CreateAssignmentStepPayload[] => {
    return steps
      .filter((step) => !stepIsRemoved(step))
      .map((step) => step.activity);
  };

  const handleBack = () => {
    setShowPreview(false);
    setFirstSelectedResource(false);
  };

  const services = useServices();
  const launchLink = React.useMemo(
    () =>
      editView && id
        ? new URL(
            renderRouteUrl(launchScreen, { id } as unknown as undefined, { t: services.launchToken }),
            window.location.href
          ).href
        : "",
    [id, editView, services.launchToken]
  );

  const handleLaunch = (e?: React.MouseEvent) => {
    e?.preventDefault();
    window.open(
      services.authProvider.getAuthorizedLinkUrl(launchLink),
      "_blank",
      "noreferrer"
    );
  };

  const handlePreview = () => {
    setShowPreview(true);
    setFirstSelectedResource(false);
  };

  const handleSave = () =>
    onSave(
      prepareFlatActivitiesForSave(flatActivities),
      hasCustomOrder,
      assignmentOptions
    );
  const allRemoved = flatActivities.every((step) => step.removed);

  return (
    <styles.ActionBar
      hide={subScope === "support"}
      flexEnd={
        !onShowSubmissions && (!showPreview || isLegacyAssignmentInCopiedCourse)
      }
    >
      {showPreview ? (
        isLegacyAssignmentInCopiedCourse ? (
          <ButtonGroup
            right={{ handler: handleLaunch, text: "View as student" }}
          />
        ) : (
          <ButtonGroup
            left={{ handler: handleBack, text: "Back" }}
            middle={
              editView
                ? { handler: handleLaunch, text: "View as student" }
                : undefined
            }
            right={{
              handler: handleSave,
              text: editView ? "Update" : "Create",
              disabled: loading,
            }}
          />
        )
      ) : (
        <ButtonGroup
          left={
            onShowSubmissions
              ? {
                  handler: onShowSubmissions,
                  text: "Back",
                }
              : undefined
          }
          middle={{
            handler: () => setFlatActivities([]),
            text: "Clear selection",
          }}
          right={{
            handler: handlePreview,
            text: "Review assignment",
            disabled: flatActivities.length === 0 || loading || allRemoved,
          }}
        />
      )}
    </styles.ActionBar>
  );
}
