import { Failure } from '@openstax/lti';
import { colors, ErrorContext } from '@openstax/ui-components';
import uniq from 'lodash/fp/uniq';
import React from 'react';
import styled from 'styled-components';

function failuresFromError(error?: Error) {
  return error && 'failures' in error ? error['failures'] as Failure[] : [];
}

export const MultipleFailureMessages = () => {
  const sentryError = React.useContext(ErrorContext).error;
  const error = sentryError?.error;

  const failures = failuresFromError(error);
  const uniqueMessages = uniq(failures.map((failure) => failure.message));

  // If there's only 1 unique message, it's already included in the heading instead
  return uniqueMessages.length <= 1 ? null : <ul>
    {uniqueMessages.map((failureMessage) => <li key={failureMessage}>{failureMessage}</li>)}
  </ul>;
};

// https://stackoverflow.com/a/56758842
const StyledDetails = styled.details`
  summary {
    color: ${colors.palette.mediumBlue};
    list-style: none;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary::after {
    content: ' ⌃';
    vertical-align: sub;
  }

  &[open] summary::after {
    content: ' ⌄';
    vertical-align: super;
  }
`;

const StyledDetailsBody = styled.div`
  background-color: ${colors.palette.neutralLightest};
  max-height: 16rem;
  overflow: auto;
  padding: 1.5rem;
`;

export const StudentsNotInCourseDetails = () => {
  const sentryError = React.useContext(ErrorContext).error;
  const error = sentryError?.error;

  const failures = failuresFromError(error);
  const studentNotInCourseFailures = failures.filter((failure) => failure.message === 'Student not in course');

  const detailsRef = React.useRef<HTMLDetailsElement>(null);

  const onClick = React.useCallback((event: React.MouseEvent) => {
    // This logic is inverted because onClick runs before the browser's default event, which toggles the details state
    event.currentTarget.textContent = detailsRef.current?.open ? 'View students' : 'Hide students';
  }, []);

  return studentNotInCourseFailures.length === 0  ? null : <StyledDetails ref={detailsRef}>
    <summary onClick={onClick}>View students</summary>

    <StyledDetailsBody>
      Students not in your course:
      <ul>
        {studentNotInCourseFailures.map((failure, i) => <li key={i}>{`${failure.name} - ID: ${failure.userId}`}</li>)}
      </ul>
    </StyledDetailsBody>
  </StyledDetails>;
};
