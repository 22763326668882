import * as UI from '@openstax/ui-components';

type ButtonProps = {
  disabled?: boolean;
  handler: (e?: React.MouseEvent) => void;
  text: string;
};

const ButtonGroup = (props: {left?: ButtonProps; middle?: ButtonProps; right: ButtonProps}) => {
  const {left, middle, right} = props;

  return <>
    {left
      ? <UI.Button disabled={left['disabled']} variant='light' onClick={left['handler']}>
        {left['text']}
      </UI.Button>
    : null}
    <div>
      {middle
        ? <UI.Button disabled={middle['disabled']} variant='secondary' onClick={middle['handler']}>
            {middle['text']}
          </UI.Button>
        : null}
      <UI.Button disabled={right['disabled']} onClick={right['handler']}>
        {right['text']}
      </UI.Button>
    </div>
  </>;
};

export default ButtonGroup;
