import React from 'react';
import * as UI from '@openstax/ui-components';
import styled from 'styled-components';

export const StyledBreadcrumbs = styled.nav`
  color: ${UI.colors.palette.neutralThin};
  margin-bottom: 0.8rem;
  line-height: 2rem;

  ol, li {
    all: unset;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    color: ${UI.colors.palette.mediumBlue};
    padding: 0;
    font-size: 1.4rem;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Separator = styled.div`
  border: solid ${UI.colors.palette.pale};
  border-width: 0 .3rem .3rem 0;
  display: inline-block;
  padding: .3rem;
  margin: 0 0.8rem 0 0.4rem;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

type BreadcrumbProps = {
  url?: string;
  text: string;
};

const Breadcrumbs = (props: {
  warningHandler: React.MouseEventHandler<HTMLButtonElement>;
  items: BreadcrumbProps[];
}) => {
  return <StyledBreadcrumbs aria-label="Breadcrumbs">
    <ol>
      <li>
        <UI.ButtonLink onClick={props.warningHandler}>All books</UI.ButtonLink>
      </li>
      {props.items.map((item, i) => {
        const ariaCurrent = i + 1 === props.items.length ? 'location' : 'false';
        return <li key={i}>
          <Separator />
          {item.url
            ? <a href={item.url} target="_blank" rel="noreferrer" aria-current={ariaCurrent}>{item.text}</a>
            : <span aria-current={ariaCurrent}>{item.text}</span>
          }
        </li>;
      })}
    </ol>
  </StyledBreadcrumbs>;
};

export default Breadcrumbs;
